<template>
    <div
        v-if="profile"
        class="company-container"
    >
        <div class="body">
            <b-card
                class="mt-2 mb-3 px-2 py-2"
                no-body
            >
                <div
                    :style="{backgroundImage: `url(${profile.company.BackgroundHeaderUrl})`}"
                    class="info-panel"
                >
                    <div class="logo-container p-1">
                        <b-img :src="profile.company.LogoUrl"/>
                    </div>
                    <div class="cover">


                        <div class="labels">
                            <h5>{{ i18nT(`Welcome to the job board page of`) }}</h5>
                            <h1>{{ profile.company.Label }}</h1>
                            <div v-if="profile.company.ShowContact" style="padding-bottom: 6px;">
                            <span>
                                <feather-icon
                                    icon="MapPinIcon"
                                    size="18"
                                />
                                {{ profile.company.Address }}, {{ profile.company.City }}, {{ `${profile.company.State ? profile.company.State + ' ,' : ''}` }} {{ countries[profile.company.Country] }} {{ profile.company.Zip }}
                            </span>
                                <b-link :href="`tel:`+profile.company.BusinessPhone" class="text-white ml-1"
                                    v-if="profile.company.BusinessPhone"
                                >
                                    <feather-icon
                                        icon="PhoneIcon"
                                        size="18"
                                    />
                                    {{ profile.company.BusinessPhone }}
                                </b-link>
                                <b-link :href="`mailto:`+profile.company.BusinessEmail" class="text-white ml-1"
                                    v-if="profile.company.BusinessEmail"
                                >
                                    <feather-icon
                                        icon="MailIcon"
                                        size="18"
                                    />
                                    {{ profile.company.BusinessEmail }}
                                </b-link>
                            </div>
                            <div>
                                <span>
                                    <feather-icon
                                        icon="TagIcon"
                                        size="18"
                                    />
                                    {{ companyType }}
                                </span>
                                <b-link :href="profile.company.Website" class="text-white ml-1" target="_blank"
                                    v-if="profile.company.Website"
                                >
                                    <feather-icon
                                        icon="LinkIcon"
                                        size="18"
                                    />
                                    {{ `${profile.company.Website}` }}
                                </b-link>

                            </div>
                        </div>
                    </div>
                </div>

                <b-row class="my-3">
                    <b-col cols="6">
                        <div class="w-100 mb-2" v-if="profile.company.CompanyVideo">
                            <iframe width="100%" height="315" :src="convertToEmbedURL(profile.company.CompanyVideo)"
                                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                                </div>

                        <div v-if="profile.company.Description">
                          <hr class="invoice-spacing"/>
                            <h2 class="section-label mb-2">{{ i18nT(`About us`) }}</h2>
                            <p v-html="profile.company.Description"></p>
                        </div>

                    </b-col>

                    <b-col cols="6">
                        <b-row v-if="profile.company.ShowContact">
                            <b-col sm="12" v-if="socialsList.find(social =>profile.company[social])">
                                <cv-social-links :candidateDetails="profile.company"></cv-social-links>
                            </b-col>
                            <b-col sm="12">
                                <hr class="invoice-spacing"/>
                                <h2 class="section-label">{{ i18nT(`Our contacts`) }}</h2>
                                <div class="social-media">
                                    <b-link
                                        class="d-block w-100 mb-1 mt-1 ml-1"
                                        :href="`tel:`+profile.company.BusinessPhone"
                                        target="_blank"
                                        v-if="profile.company.BusinessPhone"
                                    >
                                            <feather-icon
                                                icon="PhoneIcon"
                                                size="18"
                                                variant="white"
                                                class="sk-circle-icon"
                                            />
                                        <span class="ml-1">{{profile.company.BusinessPhone}}</span>
                                    </b-link>

                                    <b-link
                                        class="d-block w-100 mb-1 ml-1"
                                        :href="`mailto:`+profile.company.BusinessEmail"
                                        target="_blank"
                                        v-if="profile.company.BusinessEmail"
                                    >
                                            <feather-icon
                                                icon="MailIcon"
                                                size="18"
                                                variant="white"
                                                class="sk-circle-icon"></feather-icon>
                                        <span class="ml-1">{{profile.company.BusinessEmail}}</span>
                                    </b-link>

                                    <b-link class="d-block w-100 mb-1 ml-1" :href="`https://www.google.com/maps/search/?api=1&query=${profile.company.Address}+${profile.company.City}+${profile.company.State}+${profile.company.Country}+${profile.company.Zip}`" target="_blank">
                                            <feather-icon
                                                icon="MapPinIcon"
                                                size="18"
                                                variant="white"
                                                class="sk-circle-icon"></feather-icon>
                                        <span class="ml-1">{{profile.company.Address}}, {{profile.company.City}}, {{profile.company.State}}, {{countries[profile.company.Country]}} {{profile.company.Zip}}</span>
                                    </b-link>
                                </div>
                            </b-col>
                        </b-row>


                    </b-col>
                </b-row>

            </b-card>

            <b-row
                v-if="displayOffers.length > 0"
                class="mt-3"
            >
                <b-col cols="3" class="pt-2">
                    <h4 class="pt-05">{{i18nT(`Current openings`)}}</h4>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        :label="i18nT(`Category`)"
                        label-for="companySize"
                    >
                        <v-select
                            :clearable="false"
                            v-model="category"
                            :options="categories"
                            label="text"
                            :reduce="c => c.value"
                            @input="onFilterUpdate"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        :label="i18nT(`Location`)"
                        label-for="companySize"
                    >
                        <v-select
                            :clearable="false"
                            v-model="location"
                            :options="locations"
                            label="text"
                            @input="onFilterUpdate"
                            :reduce="c => c.value"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col
                    cols="12" sm="12" lg="6" xl="4" class="mb-3"
                    v-for="(offer, index) in displayOffers"
                    :key="index"
                >
                    <b-card
                        class="job-card cursor-pointer d-flex flex-column h-100"
                        no-body @click="navToJob(offer)"
                    >
                        <template #header>
                            <h6 class="overflow-hidden">{{ profile.categories[`cat_${offer.Category}`] }}</h6>
                        </template>
                        <b-card-body>

                            <h4>{{ offer.Label }}</h4>
                            <p v-html="truncateStr(offer.Description, 13)"/>
                            <p v-html="truncateStr(offer.Additional, 13)"/>
                            <span class="text-muted">
                                <feather-icon icon="MapPinIcon"/>
                                 {{ i18nT(`Located in`) }}:
                                {{ offer.City }}, {{ countries[offer.Country] }}
                            </span>
                            <br>
                            <span class="text-muted" v-if="offer.SalaryFrom || offer.SalaryTo">
                                <feather-icon icon="DollarSignIcon"/>
                                {{i18nT(`Salary`)}}: {{ {amount: offer.SalaryFrom, currency: offer.Currency} | currency }} - {{ {amount: offer.SalaryTo, currency: offer.Currency} | currency }}
                            </span>
                            <div class="demo-inline-spacing">
                                <b-badge
                                    v-for="skill in requiredSkills(offer)"
                                    :key="skill"
                                    variant="primary"
                                >
                                    {{ skill }}
                                </b-badge>
                            </div>
                        </b-card-body>
                        <b-card-footer
                            v-if="guestOrFreelancer"
                            style="text-align: right;"
                        >
                            <b-link
                                v-if="!offer.CurrentUserHasApplied"
                                :href="`/apply/${offer.Label.split(' ').join('-')}_${offer.Id}`">
                                <b-button variant="primary">
                                    {{ i18nT(`Apply`) }}
                                </b-button>
                            </b-link>
                        </b-card-footer>
                    </b-card>
                </b-col>

            </b-row>

        </div>


        <div class="footer pt-1">
            <span>Copyright @ {{ profile.company.Label }}</span>
            <span class="copy-right">Powered by <b-link href="https://skileo.com/">Skileo</b-link>©</span>
        </div>
    </div>
</template>

<script>
import {
    BImg,
    BLink,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardBody,
    BCardFooter,
    BButton,
    BBadge
} from 'bootstrap-vue'
import {dictToSelectArray} from '@core/utils/utils'
import useAuth from '@/auth/useAuth'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CvSocialLinks from '@/views/recruiting/CVComponents/CvSocialLinks.vue'
import vSelect from 'vue-select'



export default {
    components: {
        BImg,
        BLink,
        BFormSelect,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardBody,
        BCardFooter,
        BButton,
        BBadge,
        VuexyLogo,
        CvSocialLinks,
        vSelect
    },
    data() {
        return {
            profile: null,
            countries: {},
            industries: [],
            locations: [
                {
                    value: "",
                    text: this.i18nT(`Any location`)
                }
            ],
            categories: [
                {
                    value: "",
                    text: this.i18nT(`Any category`)
                }
            ],
            location: "",
            category: "",
            displayOffers: [],
            jobOffers: [],
            socialsList: [
                'Facebook',
                'Twitter',
                'Linkedin',
                'Instagram',
                'Youtube',
                'Pinterest',
                'Behance',
                'Github',
                'Dribble',
                'Website',
                'Tiktok'
            ]

        }
    },
    created() {
        const components = this.$route.params.id.split('_')
        const companyId = components[components.length - 1]

        this.$http
            .get(`companies/publicCompany?id=${companyId}`)
            .then(({data}) => {
                this.profile = data.data
                this.categories = dictToSelectArray(data.data.categories)
                this.categories.unshift({
                    value: "",
                    text: `Any category`
                })
                
                this.locations = dictToSelectArray(this.profile.locations)
                this.locations.unshift({
                    value: "",
                    text: `Any location`
                })



                this.displayOffers = this.jobOffers = data.data.offers
            }).catch(() => {
            this.$router.push({name: 'error-404'})
        })

        this.$http.get(`system/countries`).then(({data}) => {
            this.countries = data.data
        })

        this.$http.get(`system/industries`).then(({data}) => {
            this.industries = dictToSelectArray(data.data)
        })
    },
    computed: {

        guestOrFreelancer() {
            let userType = useAuth.getUserType();
            return (!userType || (userType.toLowerCase() !== 'client' && userType.toLowerCase() !== 'staff' && userType.toLowerCase() !== 'employee'))
        },
        companyType() {
            if (this.profile && this.industries.length > 0) {
                return this.industries.find(
                    e => parseInt(e.value) == this.profile.company.Industry
                ).text
            }
            return ''
        }
    },
    methods: {
        convertToEmbedURL(url) {

            let video_id = '';
            if (url.includes('youtube.com')) {
                video_id = url.split('v=')[1];
                let ampersandPosition = video_id.indexOf('&');
                if(ampersandPosition != -1) {
                    video_id = video_id.substring(0, ampersandPosition);
                }
            } else if (url.includes('youtu.be')) {
                video_id = url.split('.be/')[1];
            } else if (url.includes('vimeo.com')) {
                video_id = url.split('.com/')[1];
                return "https://player.vimeo.com/video/" + video_id;
            }

            if (video_id !== '') {
                return "https://www.youtube.com/embed/" + video_id;
            } else {
                return "Invalid URL";
            }
        },

        navToJob(jobOffer) {
            let publicUrl = `${window.location.protocol}//${window.location.host}/apply/${jobOffer.Label.split(' ').join('-')}_${jobOffer.Id}`;
            window.location.href = publicUrl;
        },
        requiredSkills(offer) {
            const skills = []
            for (let obj in offer.RequiredSkills) {
                skills.push(offer.RequiredSkills[obj])
            }
            return skills
        },
        onFilterUpdate() {

            this.displayOffers = this.jobOffers.filter(offer => {
                if (this.location) {
                    const city = this.location.split('_')[1]
                    const country = this.location.split('_')[2]

                    if (offer.City != city || offer.Country != country) {
                        return false
                    }
                }

                if (this.category) {
                    const category_id = this.category.split('_')[1]

                    if (offer.Category + '' != category_id) {
                        return false
                    }
                }

                return true
            })
        }
    }
}
</script>

<style lang="scss">

@import '@core/scss/vue/libs/vue-select.scss';

.sk-circle-icon {
    position: relative;
    top: -1px;
    right: 8px;
}


.radius-100 {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
}

.jobs-pane {
    display: grid;
    grid-rows: 3;
}

.job-card:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.demo-inline-spacing > * {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}
a.brand-logo {
    display: block;
    width: 200px;
    padding: 20px 0px;
    height: 100%;
    text-align: left;

    svg {
        height: 100%;
    }
}

.logo-container {
    width: 200px;
    left: 40px;
    top: 220px;
    /*margin-left: -15px;
    margin-top: -15px;*/
    background: white;
    position: absolute;
    z-index: 90;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.05);
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.company-container {
    background-color: #fafafa;

    .header {
        height: 80px;
        background-color: white;
        border-bottom: solid 1px #f3f3f3;
        display: flex;
        justify-content: space-between;
        padding-left: 160px;
        padding-right: 160px;
        align-items: center;
        box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.1);

    }

    .footer {
        height: 48px;
        border-top: solid 1px #f3f3f3;
        padding-left: 160px;
        padding-right: 160px;
        background: white;
        box-shadow: 5px -2px 15px rgba(0, 0, 0, 0.1);

        .copy-right {
            float: right;
        }
    }

    .body {
        padding-left: 160px;
        padding-right: 160px;

        .info-panel {
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 350px;
            border-radius: 5px;

            .cover {
                height: 100%;
                height: 100%;
                background-image: linear-gradient(transparent, #363636cf);
                padding: 16px;
                position: relative;
                border-radius: 5px;

                h5,
                h1 {
                    color: white;
                }

                span {
                    color: white;
                    margin: 4px;
                }

                .labels {
                    position: absolute;
                    width: 100%;
                    height: 130px;
                    bottom: 0px;
                    left: 240px;
                }
            }
        }
    }
}
</style>
